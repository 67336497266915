<template>
<div class="align-center">
  <div class="empty-hint">
    <img src="~assets/images/public/empty-prompt-icon.png" alt="">
    <p>暂无相关数据</p>
  </div>
</div>
</template>

<script>
export default {
  name: "EmptyPrompt"
}
</script>

<style lang="less" scoped>
.empty-hint{
  width: 140px;
  margin-top: 120px;
  img{
    width: 140px;
    height: 120px;
  }
  p{
    font-size: 16px;
    font-weight: 400;
    color: #93999F;
    line-height: 20px;
    text-align: center;
    margin-top: 16px;
  }
}
</style>
