<template>
  <div class="home">
    <!--上部列表开始-->
    <div class="statistical-top">
      <div class="space-between" :class="is_admin_middleground ? 'full' : 'dub'">
        <router-link tag="div" to="/article/text" class="statistical-top-item">
          <div class="statistical-top-item-left align-center">
            <img src="~assets/images/index/text.png" alt="" />
          </div>
          <div class="statistical-top-item-right vertical-center">创建文章</div>
        </router-link>
        <router-link tag="div" to="/article/video" class="statistical-top-item">
          <div class="statistical-top-item-left align-center">
            <img src="~assets/images/index/video.png" alt="" />
          </div>
          <div class="statistical-top-item-right vertical-center">创建视频</div>
        </router-link>
        <router-link tag="div" to="/courses/create" class="statistical-top-item" v-if="is_admin_middleground">
          <div class="statistical-top-item-left align-center">
            <img src="~assets/images/index/book.png" alt="" />
          </div>
          <div class="statistical-top-item-right vertical-center">创建课程</div>
        </router-link>

        <!--      <router-link tag="div" to="/analysis/gold" class="statistical-top-item">-->
        <!--      <div class="statistical-top-item-left align-center">-->
        <!--        <img src="~assets/images/index/integral.png"  alt="">-->
        <!--      </div>-->
        <!--      <div class="statistical-top-item-right vertical-center">-->
        <!--        金币分析-->
        <!--      </div>-->
        <!--      </router-link>-->
        <router-link tag="div" to="/evaluate" class="statistical-top-item">
          <div class="statistical-top-item-left align-center">
            <img src="~assets/images/index/evaluate.png" alt="" />
          </div>
          <div class="statistical-top-item-right vertical-center">待回复评价</div>
        </router-link>
        <router-link tag="div" to="/user/user" class="statistical-top-item" v-if="is_admin_middleground">
          <div class="statistical-top-item-left align-center">
            <img src="~assets/images/index/sh.png" alt="" />
          </div>
          <div class="statistical-top-item-right vertical-center">待审核主播</div>
        </router-link>
        <router-link tag="div" to="/filemanagement" class="statistical-top-item" v-if="!is_admin_middleground">
          <div class="statistical-top-item-left align-center">
            <img src="~assets/images/index/course.png" alt="" />
          </div>
          <div class="statistical-top-item-right vertical-center">课程上传</div>
        </router-link>
      </div>
    </div>
    <!--上部列表表结束-->

    <!--    中部开始-->
    <!--    <div class="statistical-center">-->
    <!--      <div class="statistical-center-title vertical-center">-->
    <!--        <img src="~assets/images/index/data.png"  alt="" class="title-img">-->
    <!--        <span class="statistical-center-title-text">数据总览</span>-->
    <!--      </div>-->
    <!--      <el-divider></el-divider>-->
    <!--      <div class="statistical-center-list space-between">-->
    <!--        <div class="statistical-center-item">-->
    <!--          <div class="statistical-center-item-title">-->
    <!--            阅读（播放）总数-->
    <!--          </div>-->
    <!--          <div class="statistical-center-item-num">-->
    <!--            6,560-->
    <!--          </div>-->
    <!--          <el-divider></el-divider>-->
    <!--          <div class="statistical-center-item-foot vertical-center">-->
    <!--            昨日：-->
    <!--            <el-tag>+15</el-tag>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="statistical-center-item">-->
    <!--          <div class="statistical-center-item-title">-->
    <!--            点赞总数-->
    <!--          </div>-->
    <!--          <div class="statistical-center-item-num">-->
    <!--            560-->
    <!--          </div>-->
    <!--          <el-divider></el-divider>-->
    <!--          <div class="statistical-center-item-foot vertical-center">-->
    <!--            昨日：-->
    <!--            <el-tag>+15</el-tag>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="statistical-center-item">-->
    <!--          <div class="statistical-center-item-title">-->
    <!--            评论总数-->
    <!--          </div>-->
    <!--          <div class="statistical-center-item-num">-->
    <!--            120-->
    <!--          </div>-->
    <!--          <el-divider></el-divider>-->
    <!--          <div class="statistical-center-item-foot vertical-center">-->
    <!--            昨日：-->
    <!--            <el-tag>+15</el-tag>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="statistical-center-item">-->
    <!--          <div class="statistical-center-item-title">-->
    <!--            收藏总数-->
    <!--          </div>-->
    <!--          <div class="statistical-center-item-num">-->
    <!--            350-->
    <!--          </div>-->
    <!--          <el-divider></el-divider>-->
    <!--          <div class="statistical-center-item-foot vertical-center">-->
    <!--            昨日：-->
    <!--            <el-tag>+15</el-tag>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--中部结束-->

    <!--    下部统计表开始-->
    <div class="statistical-bottom">
      <div class="statistical-bottom-title vertical-center space-between">
        <div class="">
          <img src="~assets/images/index/new.png" alt="" class="title-img" />
          <span class="statistical-bottom-title-text">最新发布</span>
        </div>
        <div class="statistical-bottom-title-right f-r" @click="$router.push('/creation/creation')">
          查看全部&nbsp
          <img src="~assets/images/index/right.png" alt="" class="right" />
          <img src="~assets/images/index/right-h.png" alt="" class="right-h" />
        </div>
      </div>
      <el-divider></el-divider>
      <div class="statistical-bottom-list" v-if="creationData.data && creationData.data.length">
        <div class="statistical-bottom-item" v-for="(item, index) in creationData.data" :key="index">
          <div class="statistical-bottom-item-top vertical-center">
            <el-tag v-if="item.content_type === 1">文章</el-tag>
            <el-tag v-if="item.content_type === 2">视频</el-tag>
            <el-tag v-if="item.content_type === 3">推广</el-tag>
            <div class="statistical-bottom-item-top-title white-space">{{ item.title }}</div>
            <div class="statistical-bottom-item-top-right flex-nowrap">
              <span style="max-width: 100px" class="white-space">{{ item.author || item.created_user.nickname }}</span> 于
              {{ item.created_at_humans }}
              编辑
            </div>
          </div>
          <div class="statistical-bottom-item-center space-between">
            <img :src="item.cover_picture" alt="" class="statistical-bottom-item-center-left" />
            <div class="statistical-bottom-item-center-right">
              <div class="statistical-bottom-item-center-right-btm" v-html="item.text" v-if="item.content_type !== 2"></div>
              <div class="statistical-bottom-item-center-right-btm" v-html="item.summary" v-else></div>
              <div class="source vertical-center">
                <span v-if="item.create_type == 1">原创</span>
                <span v-if="item.create_type == 2" class="source-zz white-space">转载来源:{{ item.source }}</span>
              </div>
            </div>
          </div>
          <div class="statistical-bottom-item-btm space-between vertical-center">
            <div class="statistical-bottom-item-btm-left">阅读{{ item.read_count }} · 点赞{{ item.good_count }} · 收藏{{ item.collect_count }} · 评论{{ item.comment_count }}</div>
            <div class="statistical-bottom-item-btm-right">
              <el-tag v-for="(val, index) in item.tags.slice(0, 4)" :key="index">{{ val.name }}</el-tag>
            </div>
          </div>
        </div>
      </div>
      <empty-prompt style="margin-bottom: 89px" v-else></empty-prompt>
    </div>
    <!--    下部统计表结束-->
  </div>
</template>

<script>
import EmptyPrompt from 'components/EmptyPrompt';
export default {
  name: 'Index',
  components: {
    EmptyPrompt,
  },
  data() {
    return {
      creationData: [],
      is_admin_middleground: false, // 管理员
    };
  },
  created() {
    this.getList();
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  watch: {
    userInfo(newValue) {
      console.log(this.userInfo, '==================');
      this.is_admin_middleground = this.userInfo.is_admin_middleground;
    },
  },
  methods: {
    getList() {
      let url = this.$api.DiscoverContents + `?page=1&pageSize=2&status=1`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.creationData = res.data.data;
          for (let i = 0; i < this.creationData.data.length; i++) {
            this.creationData.data[i].text = this.creationData.data[i].content.replace(/<\/?.+?>/g, '');
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/index/index.less';
/*/deep/ .circles-nav-wrap{
  height: 640px;
}*/
.full {
  width: 100%;
}
.dub {
  width: 65%;
}
</style>
